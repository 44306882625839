import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Female empowerment`}</h2>
    <p>{`One of the main conversations Raw Korean founders and I had was regarding the harsh name of the company. `}</p>
    <p>{`Although initially conceived to show the power of women, they wanted a toned down brand.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cfaa18665e4e6f368d19d146775ef607/af590/before-after_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/0lEQVQoz3VRPWtUURDdX2FjY2mhhT/AiCDYKFhEcIWopbWVH9kkz2LJR5NCFERsxE47xWBlgsUGTJTYiKLu6n5lsx/v3vuSvHdnzozct7tBxFw43GHmzLkz5xZiY87Hxiwaa2e3OztRo9WKGs1WtNPtRrExc7Ex5diYY7Exx2Nj5kOuPxhEzRGv1+9HsTWzsTELsTFnCta5h9Y5dUmizVZLf1ZrWq3VdLvTyXOhZp07Z52bHMXa7fX0d72uP6rVPLbugFcuAFgQERXBvoqQCkhVSIS9iGShxsynAVwIsaqGnM+5qgFeVUOvArhXEJElHRJDQf4BZEicAHAxkIjBOmyQvQxCxJJmRMwIqVKYcCkQkVli80uovanU3lBqVASpQehk9hM2SXPBmUerPFlaEf36Vh8sP9OTU8/lZvkNAWHLvwTTRoX2v7yUZHVak7UZTd7dETa18KyyTw8E5x6/58u3X4m+uKWbT+f17pNP8nrtG+VeeBoK5vODvISLvQAkAIsIEJYLK2eecsHKVpur9RWpfPgo6xs1IdqS9c/fybjgmJaCh4tjs1U0TDQGB8vGHjIPPXS7njwN2O6lsLuMNOuiF7vMU+7hdJhwefjLhwPAWQCXRg8fegDcLzDzKQA3AFwBUPwPpojoCDEfBXCNmIvMdJWYczDyuMjAdWY+8QdPvNAWuM54rwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Before and after",
            "title": "Before and after",
            "src": "/static/cfaa18665e4e6f368d19d146775ef607/af590/before-after_10.png",
            "srcSet": ["/static/cfaa18665e4e6f368d19d146775ef607/772e8/before-after_10.png 200w", "/static/cfaa18665e4e6f368d19d146775ef607/e17e5/before-after_10.png 400w", "/static/cfaa18665e4e6f368d19d146775ef607/af590/before-after_10.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Rebrand focused on fun`}</h2>
    <p>{`However their initial take on the brand felt like it lost personality in the process of toning down. `}</p>
    <p>{`To deevelop a fun and engaging brand the colors, logo and communication style were reworked.
By working with the insipirational figure of an archer various `}<a parentName="p" {...{
        "href": "https://www.behance.net/gallery/131581583/Brandbook-web-design-RawKorean"
      }}>{`brand proposals`}</a>{` were created by `}<a parentName="p" {...{
        "href": "https://www.behance.net/Galya_Kimka302c"
      }}>{`@galya_kimka302c`}</a>{`. `}</p>
    <div className="row my-5">
      <div className="col-md-4">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2f3eac4542311d7405a114ee29fe5e58/df56e/rawkorean_6.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADpUlEQVQ4y6WT3U9adxjHzx+y291taeblbpZ0SZftZtN0TYxdtrSb3bQTN+1GV+sLbWzBZeJYmWtTqFKmyFAQRHlRKiBvhXM4oIAHKBx5mxyRw+Ht0PO20Lqs682S9Zvnl+eX5/f9Pr+rD8D9H7HPG/DfRpY98bMvP52EnzIcw3GNVgurEsVqtVSvl+qNKklRFP2iu0FUSILgqChJYHQNB1imvdAOprQW2BJBxlbUCzbrktelC/giO2qHSQv6fRW8Qv9ZbR5kQ+u9GfdoM/7GnrE7btsEWIbhOM5hNQi//3ornOAvKjcsWlsiZTdoDPyLi6O8K13vo+lk6Y4TGdY14m/n3V2FjTkceg1HPgOoVqN2lMqlwtlMbkXnGJmXKY1qK+y2qHWGW2KZQNj3QZdRa8rOqsDxB0XYVE/3P966l4A6i8mvAJI41N4dduslWsfOxcnpodn7fbdF3fzxj4dvuLyg/J6ip4c3fX0Ks+oPllWH8TiCOBacIY1r17BbBup4YVshcK/MvNM/cFp0u4P37ZtnPhybnDh/+YJw6tr6gzGJ8NL27BTy0IqqXWh4w+0QmG1hlcQWBDMASZRQpxr16ft//KlHLD47KRoQTKrUst8XpDLpDa+KD2v5GaOScuWry76AQqFRKRBzAJ16XNQhQKGAWaxO66YLCoVh2KsUjeyCzpR57hAJbC9M68VfrP3KE1w+a7FYkzPS8M0xx+hgxmQu1giKpgChcO6tU+c6Os4LBL8k9u2q+bsbZn0pA6ef+Jb4nyxPdK/Jrw0Pfvn5p52ygT7HqhKal2YgsEXRHMsCEsniu6d73zvTNz4ucTrW55Y0Hq89mw+FY49CrpV93+otwdUr3/3wUde5qyPX99BYKAwWixhO1BiaBrJpBPRDUdAVDIY2N22rWp3H44vGIoV0MAvtZAJ+RHPB/9uppPz15HqvOZFYdluQJ0itVidJEiikI7mDRBnL5PNZt8cNQWAYDnr8fqpy/If85uDM0L5Juut8KDfd+cZwf2LHMfGzRGQ2V4lKiySBVrNRrRHxSHTP6axUcGgPWYuipVyeS6OQfVUtHoL1ct22+ZLR0mnf4T3amlYviWy2erPZarXaYDylqCOsiOVzePk4GTvYyuEN7IiDYbyAxmx6BsuiRDlax2NVfL9SjuDHoXwWw7B6rQY8Y479F6jsyYRhn1PzsggcJ8kmTdPAP9SyLMdy7LNqH4ZpjxiaY9o3+u9iXvgK4F5BrxT+C2al1SUhU3YpAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebrand",
                "title": "Rebrand",
                "src": "/static/2f3eac4542311d7405a114ee29fe5e58/5a190/rawkorean_6.png",
                "srcSet": ["/static/2f3eac4542311d7405a114ee29fe5e58/772e8/rawkorean_6.png 200w", "/static/2f3eac4542311d7405a114ee29fe5e58/e17e5/rawkorean_6.png 400w", "/static/2f3eac4542311d7405a114ee29fe5e58/5a190/rawkorean_6.png 800w", "/static/2f3eac4542311d7405a114ee29fe5e58/df56e/rawkorean_6.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-4">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/086ba74cdea05d2a0235fe7ed431315b/df56e/rawkorean_7.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADHElEQVQ4y6XU+VOSQRgHcP+yphJRXhHxpPEmy0AzPBDwSAgFj/TlUHBeHTxQPBsVxdRRxEbHI653whO8Iqy8YCCVRHm3URubEvvFnWd29pfPfPfZ2d0gcI8RdDlhGADAf+o+39T7tgyuNb17Xf9z0wB2jNfltS56rQtgWw+2DcDruYLYDfYDAHzb5rO3eHd1+Dwn1FQc5hBAfnHEhYgI5DF2Zf6Oig00RVgNHjiWbsgf7LejQEI8hsmGEmiZB+1XEUF9JFZHAg3RXxV0R2s26M7EJBFgNxAGV9gDk6c40bMlsSsCyn5twveaBKcocYkfZ+bGAlk0kBAD47MdFIjDv1XHUFLLyM+RREYHla2mstVJTA0poy2d0XggTgFSAuZY/gtjV6uTLdRRHrbCj0qjV7DKW/L4nVGv1FE5gySGhvCik/VG6ZSmAAkB210OkIzZ0cNKgo4TGU8VxmU1RmcgEK03ntGeylaF0zqrRR2u/+Dzz+gqFz/GjqQ8FTxOrM8obElg9j6kdoXR3lGyFLVw86EkNQC+3rZ3GzUWhUywyEyOjMYbbEYUyjokubCHXTNMpKlE9SqnJFAy5r/u2bxYiJfn4mKTKTy4QFDB53J4lXJN1+Boa9dQaYnkAE4CUujWgf3G6GQ+LjsvjSWgc2szY9JfPkgT51R2j2hnPPapCSnzRJYExFDg5ONN81hOcAK1lJrbFJ/eQKez2qRwOTLeP6yxmtQbAzygSMJgCATs+WwH1TFxPC6fyoDJz+qYea/1s8Nn+/PWT9o5rcrSywNNiUB0G18le7fQaSZOKRc2NyMVsKK6RmazTG987Ed1PdrRTqOS5ZQ+8d+F3Rvm0VzcUItwsadyQS3VDCD7W1rb6vSKRTej61ublB83JO8JQi8cd+Dx3GCluEjTXjX3vrFfmLEkox/3ML19Bab6zJWGLIDE+2Do/MtSAOyymj7kPxrJJ6gLIgxckqU4xMDBOfghe2V4Ixu3XIxzVkEHwlCf3fLPDbt82ecnHte60W0zeWxGj9XgtpmdVtOR1eS8LtvlfLRu9J3+uCFB9/mGfgFmLaMSntURiAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebrand",
                "title": "Rebrand",
                "src": "/static/086ba74cdea05d2a0235fe7ed431315b/5a190/rawkorean_7.png",
                "srcSet": ["/static/086ba74cdea05d2a0235fe7ed431315b/772e8/rawkorean_7.png 200w", "/static/086ba74cdea05d2a0235fe7ed431315b/e17e5/rawkorean_7.png 400w", "/static/086ba74cdea05d2a0235fe7ed431315b/5a190/rawkorean_7.png 800w", "/static/086ba74cdea05d2a0235fe7ed431315b/df56e/rawkorean_7.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-4">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9e1b1321a6cc943c2a48c7a2f1489aad/df56e/rawkorean_2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADeklEQVQ4y6WS+1NbRRTH86eof4PjD3Y6NA2U+yKFVihNyyutIa+b3b0hIYGEEDUkpc00UAc7aahIwMYmWvK4u5dcGrVPaVF/ELT+wNjpiGOGTguDtIVgcGgSxuKMP+iZM7uz58znnO/uWcX2/zBFsbhd8n/av8eLxW1FOVAobD15Wlxd3Xjy9Nny8p8rK8Vnz0uZ5xtbq2uba+uF1T82VtZerK1vbm2VSypm53+//ePjn+4uvMDSL9HYVZtZPvPB+szMbzfv3VlYnl3IS7ceTV1/OBi5qe2NR+LfyXd+/WpuaXYh/818XkHxmX36jM+X2ZzOLobHRND2YHS4kMvlxqUqk6gypoMT36dvLwWDV2JOcC3zbe5e3j50d78uWcuLCrWAlTw+F0gXpkg+6np0WbU0eWQ7O37rE5kSSINN8obvjyV/no9ZFscOPiC+hLzY6b9Ra8lwAlZwaAfufz/18FJiLsDnwo1zo82P4+OpEXyQFzmI1XbyluFa/EPjjXDzxyHhDW3izXcTaoHQECtYiGmI65DYJKTb7Knj7uwxp9zaTeoFkYaYhVhlTr+tT3IoeaInfcCQ3GdI0kBkIGYgVpQ2CuJDAKug3Nn9qckRVfISBXApxSFyWCAUnK4BMosktUBYiF+BOYgpNH3SdnXGp/9ywNBuj9dCwkKxUpocFaY0XZ9TgDAVsgzvaLNkWuBErTltNA8Cy1mlQaSAxKIyTENpyPVepM9Rj1L0XhiJKpOk9YwOTPX0as0hncE2Majtu3LIPM0izMCMCkiazmEbCBwAWQpmdxUpWIRpgOuEzOTX4HJKH3TToTMdgUC7xyGwAmGBWA2kC06Pv/1EyGy65Oo9hcZrKvzOqKqNsuFcZCTW2aAbsoZhPKRxNbzjGXae9k8oTdfbrJMXXD39Ax0Ory7UpR1zOWpABWaRWG2STcFId0C/vzVg9fau9L8eMmkNgYst7s+qebnRGqs3+Nr6zjYB96me837/xTpLioakLJuykEZH8qTT625p3vS+9oO/qq7pdKvF02BN0YAwCDPt7mO854ihX6NDzV1RCkhMqfPLq4scwEpLMmrUEFTVYg8ctQwcNo1Unppw4As1/5GaH6Y7znMgxUDyypwZKLKIcCCl4hNqlOCQyKDsburlKjGQsIK8I2TPJ9ltwiKJLhcW/54qH4G495P8Z/8LLE699i2b4TIAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebrand",
                "title": "Rebrand",
                "src": "/static/9e1b1321a6cc943c2a48c7a2f1489aad/5a190/rawkorean_2.png",
                "srcSet": ["/static/9e1b1321a6cc943c2a48c7a2f1489aad/772e8/rawkorean_2.png 200w", "/static/9e1b1321a6cc943c2a48c7a2f1489aad/e17e5/rawkorean_2.png 400w", "/static/9e1b1321a6cc943c2a48c7a2f1489aad/5a190/rawkorean_2.png 800w", "/static/9e1b1321a6cc943c2a48c7a2f1489aad/df56e/rawkorean_2.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <h2>{`UI/UX`}</h2>
    <p><a parentName="p" {...{
        "href": "https://panheeisme.com/"
      }}>{`@Panhee`}</a>{` created wireframes that were used later to prototype HTML + CSS pages that reworked Raw Korean's website to incorporate design principles and better navigation elements, including a side scroll `}<em parentName="p">{`a la Netflix`}</em>{`. `}</p>
    <div className="row my-5">
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9da4b8e39c26aeb772b9846360b7801f/df56e/rawkorean_4.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACs0lEQVQ4y8VUTU8TQRjuXePBxF/A1USNP8Cj8YRgkURQUWKIFz6MRoNcJCVIQpQEUWjBBEm13S3lQ0rRipblS+UrgrS12EJZum3pdrfdhe7s7uyM2ZaDeDAxkPjkzZtnDs+8z7zvzBjwAWA4qBhCqO4DzMXfACE8jModz18UGkuv3qi8UlZeUmK8XlFRcVPnl4wlF4uLi/aHsfRycWFRt6VbF0MNzYy73eb6d90PPaOu0cEB14Azn13O/kEHSRJEP0E69Gx3EoSDJKyWnq/UJ4iQAWuw6+P0bSt53+6oHaLqhicbxmbvjkzVDFFVTq/l87KSTaY4OiOJAsKcilSM3wYiHePTGCPddnmnzVDVdLym5Wh187HaliPVzQUN7efb+s6auq71OOrJsVsv+8vMhHX22/CijwqE75DvS59Z985c3mkraGi/8LTvxL0nZ5osp0zm002Wc629J03myldDW1tB3+amj0mKWUlRVL1H3rkKsz0n1mDvxJdWz2zf9NKjkYlGF9Xookwu6vHgSE2vvcvjzXCxOLstq3BHAqIENA3OhGhyZgEjzQAA4HkeY4wQ+m0KGpQyqwtzIf9qik8kUiwr7G4LopCVOHFXQ5jneQCAQQYgybJQQ4qqyqqqQDW/Cb0dI8apiflFUUjx4k5a3OUEMQuABGRV05IsK8uyXpnjOIyxClWMUSIjPnB+qLO5PMvfbfM+z0pASNIJLq1B/R4qigIAQAixf4jztjMS6JxaavPOz4XCwSjN8Ow6Hd1gkhhjPiNgjCQgawjt2UYIUdSk9fWbUbc7FAqth8NcbIuPM/RGZD2sY2XV5/f719Z++v2+YHAtEAj+CAYjkQiEUB9VOp2haTrKMLEc6GiUjkYZRl8zDBOPx2OxOMPEciSW44wgCIfxMBBC2j8i3yDDf/tJfgHCyRMvKcDKKQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Old website",
                "title": "Old website",
                "src": "/static/9da4b8e39c26aeb772b9846360b7801f/5a190/rawkorean_4.png",
                "srcSet": ["/static/9da4b8e39c26aeb772b9846360b7801f/772e8/rawkorean_4.png 200w", "/static/9da4b8e39c26aeb772b9846360b7801f/e17e5/rawkorean_4.png 400w", "/static/9da4b8e39c26aeb772b9846360b7801f/5a190/rawkorean_4.png 800w", "/static/9da4b8e39c26aeb772b9846360b7801f/df56e/rawkorean_4.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/aa61859a68aa488c520f064ac022e6e6/df56e/rawkorean_5.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACxUlEQVQ4y8WUeU8TQRjG+6UkMYQYkbRCDxaWBlAsPbBs271KD44AxSjlCgJFDV4QAQWr7NWy3S5VCi3ILaalKAaKH0Pa3ZpyaAAT/sDEJ08m78y8vzyZZGYkmUtIclk4fSwhnU6nUhf5pPtfJHs8j6BayOlsRLE6Q41RqzP8drXWUKXRHfloxVgLaXR6j2cwCwtCCmkdvQJ0gsYBWVV3DtB+taQ9F3TnqTtzwY78W9162xNTw3ODbeh65f08dduN2105YHdrz0QmI0hE4aCpP1wIB8rrg2onD9qDpY4Z0MmXOfkSGw86Zqpd89q2iNY1l9118GUO/qaF9YwvZTJpiSikGnp5KUSoUFqFkEUWH2B+pzRTMhNV4+Kc/VETPGCHO5CmyXJnQGEhAIwuME55xhYyGTGb3NA7I4XIEpyWW/z6ByP+PtvwQ1xeN2loCXUNr1baacDytgwnVSijRCgAZwqMxODY4h9YBpHFmK8Yfz8e0K1w7kAQuzeMgdYZ1M1XNbIV9WxlPVtRPw1gzGHyGdhEqFC/2jH+lLE+fjYx8nLA/QoHrCF9e0TjmtU2c9XNnK41qLb7i8/DUogEcFoB+8xdg9NEXyLQoW0Z1eDeMXePq+WFpjV0p5EttTIlOANgzN9gjFahdKHZB7URcDsjR/1V2Jv+xg6rbajUHih3+JUIff7MKUcPn3+XUMCUAqaUKCUzMdJaRgGTRQiTD3MyhJWbSSVCKVGfHGEUCHXNMDUweggfHPycXfrGRfeDC0lydpfgv1If4r65GDm7NxX67uW2vdw2E957ze54p7+wH+PBxR+BSHL5866QPpCIoriXTMbiifjW9spGYm1hYXU5sr4ZXVlPzH/a5MNrobm1jc0tfn4zGp5LrEbiiZ1YbGsvuS+KYvZuiye68CWIp5tPwYIoCkc6rsW0kB0F8ag4nJyB/89P8gu9YYNDEIryzwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "New website",
                "title": "New website",
                "src": "/static/aa61859a68aa488c520f064ac022e6e6/5a190/rawkorean_5.png",
                "srcSet": ["/static/aa61859a68aa488c520f064ac022e6e6/772e8/rawkorean_5.png 200w", "/static/aa61859a68aa488c520f064ac022e6e6/e17e5/rawkorean_5.png 400w", "/static/aa61859a68aa488c520f064ac022e6e6/5a190/rawkorean_5.png 800w", "/static/aa61859a68aa488c520f064ac022e6e6/df56e/rawkorean_5.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      